.travelBackground {
  background: white
    url("../../../assets/images/BackgrounImages//travel_bg.webp") no-repeat center;
  background-size: 100vw auto;
  height: 100vh;
}
.footeBackgroundColor{
  background-color: #031721;
}
.homeInfoMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 70px;
  color: white;
  width: 700px;
}
.brandMarquee {
  z-index: inherit;
}
.heading1 {
  color: black;
}
.homeInfoContent {
  color: black;
}
.travelAssetImage {
  margin-top: 50px;
  .image1 {
    height: 100%;
    width: 100vw;
  }
}
.travelSection1 {
  display: flex;
  justify-content: center;
  margin-top: -702px;
}
.personImage {
  height: 700px;
  margin-left: 350px;
}
.image7 {
  border-radius: 350px;
  height: 582px;
  width: 582px;
}
.travelSection2 {
  width: 36%;
  margin-top: 20px;
  margin-left: 370px;
  margin-bottom: 260px;
}
.section2Heading {
  font-weight: 700;
  font-size: 64px;
  line-height: 81px;
  color: #00a6a6;
}
.section2WidthHeading {
  width: 140%;
  margin-top: 55px;
}
.section2WidthContent {
  width: 145%;
}
.section2Content {
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: #769393;
  margin-top: 10px;
}
.travelSection3Main {
  // background: url("../../../assets/images/industry/Travel/laptopPerson.png");
  padding: 80px;
  display: flex;
  justify-content: space-between;
}
.travelSection3 {
  width: 43%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.travelCircle {
  position: absolute;
  top: 0;
  right: 0;
}
.image2 {
  width: 100%;
}
.travelTextContent {
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  color: #769393;
  width: 25%;
  margin-top: 100px;
}
.brandMarqueeContainer {
  height: 200px;
  width: 100vw;
  background-color: white;
}
.travelSection4 {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section4Heading {
  font-weight: 700;
  font-size: 64px;
  line-height: 81px;
  color: #00a6a6;
  width: 45%;
}
.section4Content {
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: #769393;
  // width: 60%;
  margin: 22px 200px;
}
.image4 {
  // margin: 10px;
  border: 2px solid transparent;
  border-radius: 140px;
  height: 600px;
}
.image5 {
  width: 100%;
}
.image8 {
  width: 65%;
  margin-top: -560px;
}
.travelSection5 {
  margin: 100px 0px;
}
.imageBorder {
  width: 96%;
  position: absolute;
  top: 18px;
  height: 94%;
  border-radius: 116px;
  border: 2px solid #ffd68b;
}
.section5TextContainer {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section5Container {
  margin-top: -800px;
  padding: 100px;
  display: flex;
  justify-content: space-between;
}
.retailImpactCard {
  position: sticky;

  div {
    color: rgba(54, 54, 72, 1);
  }
}
.impactBackground {
  background: transparent;
  color: black;
  margin: 0px;
  border-radius: 0px;
}
.dancingBarColor {
  background-color: #00a6a6 !important;
  opacity: 0.1;
}
.impactHeading {
  display: none;
}
.bannerBackGroundcolor {
  background: white;
  background-image: url("../../../assets/images/bannerLines/gree_lines.webp"),
    linear-gradient(90deg, #00a6a6 16.22%, rgba(106, 228, 228, 0.57) 100%);
  background-size: cover;
}

.bannerHeading,
.bannerTickText {
  color: white;
}

@media (max-width: 1400px) {
  .travelBackground {
    background-size: 150vw auto;
  }

  .travelSection1 {
    margin-top: -500px;
  }
  .personImage {
    height: 500px;
  }
}

@media (max-width: 1200px) {
  .travelBackground {
    background-size: 160vw auto !important;
  }

  .travelSection1 {
    margin-top: -400px;
  }
  .personImage {
    margin-left: 200px;
    height: 400px;
  }
  .travelAssetImage {
    margin-top: 0;
  }
  .travelSection2 {
    margin-left: 200px;
    margin-bottom: 200px;

    .section2WidthHeading {
      font-size: 50px;
    }

    .section2WidthContent {
      font-size: 20px;
    }
  }
}

@media (max-width: 767px) {
  .travelBackground {
    background: url("../../../assets/images/industry/Travel/travel_bg.png")
      no-repeat bottom;
  }
  .homeInfoMainContainer {
    padding: 10px;
    width: auto;
    justify-content: start;
  }
  .heading1 {
    font-size: 32px;
    font-weight: 700;
    margin-top: 100px;
  }

  .travelSection1 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  .personImage {
    margin-left: 0;
    height: 400px;
  }
  .section2Heading,
  .section4Heading {
    font-size: 30px !important;
    text-align: center;
    line-height: normal;
  }
  .section2WidthContent,
  .section2Content,
  .section4Content {
    font-size: 18px !important;
    text-align: center;
  }
  .travelSection3Main {
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .travelSection3 {
      width: unset;
    }
  }
  .travelSection2 {
    margin: 0 !important;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    > div {
      text-align: center;
    }

    .section2WidthHeading,
    .section2WidthContent {
      width: unset;
    }

    .section2WidthHeading {
      margin-top: 25px;
    }
  }
  .travelSection4 {
    padding: 0 15px;
  }
  .section4Heading {
    width: 100%;
  }
  .section4Content {
    margin: 0;
    margin-bottom: 20px;
  }
  .image4 {
    width: 100%;
    height: auto;
    border-radius: 123px;
  }
  .imageBorder {
    top: 12px;
  }
  .travelMobileImages {
    position: relative;
    display: flex;
    justify-content: center;

    img:nth-child(2) {
      position: absolute;
      bottom: 0;
      height: 90%;
    }
  }
  .travelSection5 {
    background: url("../../../assets/images/industry/Travel/rentalMobile.png");
    background-repeat: no-repeat;
    background-size: 100%;

    .section5Container {
      flex-direction: column;
      margin: 0;
      padding: 30px 15px;
      gap: 30px;

      .section5TextContainer {
        width: 100%;
      }
    }

    .image7 {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 576px) {
  .travelBackground {
    background-size: 180vw auto !important;
  }
  .homeInfoMainContainer {
    justify-content: start;
    padding: 100px 10px;
    width: auto;
  }
  .brandMarqueeContainer {
    height: fit-content;
    padding: 50px 0;
  }
  .section2WidthContent,
  .section2Content,
  .section4Content {
    font-size: 16px !important;
    text-align: center;
  }
}
