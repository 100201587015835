@import "../../assets/scss/variables.scss";
.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}
.card {
  text-align: center;
  margin: 10px;
  height: 500px;
  width: 180px;
  box-shadow: 5px 5px 25px 20px rgba(127, 135, 169, 0.05);
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: auto 500px;
  background-position-x: -380px;
  transition-property: width, background-size, background-position-x;
  transition-delay: 1s;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
}

.contentContainer {
  z-index: 999;
  width: 300px;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: left;
  padding: 20px;
  rotate: -90deg;
  transform-origin: 10% 40%;
  height: 50px;
  overflow: hidden;
  // transition: rotate 0.4s ease-in-out;
}

.card:hover .cardSubHeader {
  animation: showSubheading 0.4s ease-in forwards;
  // animation-delay: .3s;
}

// .card:not(:hover) .cardSubHeader{
//     animation: hideSubHeading 1s ease-in forwards;
// }

@keyframes expandCard {
  0% {
    width: 180px;
  }
  100% {
    width: 300px;
  }
}

@keyframes shrinkCard {
  0% {
    width: 300px;
  }
  100% {
    width: 180px;
  }
}

@keyframes rotateText {
  0% {
    rotate: -90deg;
  }
  50% {
    translate: 0px 0px;
    height: 50px;
  }
  100% {
    translate: 0px -20px;
    rotate: 0deg;
    height: 120px;
  }
}

@keyframes rotateTextReverse {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: -90deg;
  }
}

@keyframes showSubHeading {
  0% {
  }
  50% {
    height: 20px;
  }
  100% {
    height: 100px;
  }
}

.cardHeader {
  font-weight: 500;
  font-size: 24px;
}
.cardSubHeader {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.mobHead {
  display: none;
}

.mask {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.card:nth-child(1) {
  background-image: url("../../assets/images/stack-offerings/identity.webp");
  background-position-x: -100px;
}
.card:nth-child(2) {
  background-image: url("../../assets/images/stack-offerings/financial.webp");
  background-position-y: 0px;
  background-position-x: -100px;
}
.card:nth-child(3) {
  background-image: url("../../assets/images/stack-offerings/merchant.webp");
  background-position-x: -100px;
}
.card:nth-child(4) {
  background-image: url("../../assets/images/stack-offerings/digital_\ contracting.webp");
  background-position-x: -100px;
  background-position-y: 0px;
}
.card:nth-child(5) {
  background-image: url("../../assets/images/stack-offerings/utility.webp");
  background-position-x: -100px;
}
.card:nth-child(6) {
  background-image: url("../../assets/images/stack-offerings/persona.webp");
  background-position-x: -100px;
}

.mobSubHead {
  display: none;
}

@media (min-width: 768px) {
  .card:hover .contentContainer {
    animation: rotateText 0.4s ease-in-out forwards;
    // animation-delay: 0.3s;
    // animation-duration: 1.4s;
  }

  .card:not(:hover) .contentContainer {
    // animation: rotateTextReverse 0.4s ease-in-out forwards;
    // transition-delay: .3s;
    animation: none;
    rotate: -90deg;
  }

  .card:hover {
    animation: none;
    width: 300px;
    transition-delay: 0.3s;
  }

  .card:not(:hover) {
    animation: none;
    width: 180px;
    transition-delay: 0.3s;
  }
}

@media (max-width: 767px) {
  .offeringWrapper {
    padding: 0 10px;
  }

  .dotsContainer {
    display: flex;
    justify-content: center;
  }
  .dot {
    width: 10px;
    height: 10px;
    background-color: gray;
    margin: 5px;
    border-radius: 10px;
  }
  .activeDot {
    display: inline-flex;
    width: 20px;
    background-color: #0075f3;
    height: 7px;
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
    margin-top: 7px;
  }
  .card {
    display: none;
  }
  .activeCard {
    display: block;
    // height: 700px;
    width: 100%;
    margin: 10px;
    background-size: cover;
  }
  .cardHeader {
    font-size: 12px;
  }
  .cardSubHeader {
    font-size: 9px;
    line-height: 12px;
  }

  .contentContainer {
    rotate: 0deg;
    height: 150px;
  }
  .cardSubHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  .cardHeader {
    // display: block;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
}
