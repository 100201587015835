.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  left: 60%;
  width: 50%;
}

.contactUsHeading {
  font-size: 48px;
  font-weight: 700;
  line-height: 50px;
  color: #363648;
}
.error {
  font-size: 10px;
  color: red;
  font-weight: 200;
  width: fit-content;
  margin-top: -10px;
  position: absolute;
}
.error1 {
  margin-left: 240px;
}
.inputBoxText {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background: white;
  border: none;
  padding: 20px;
  border-radius: 8px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 34.2%;
  //   max-width: 90%;
}
::placeholder {
  color: #9a9aa3;
  font-size: 14px;
  font-weight: 200;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.inputBoxTextArea {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  background: white;
  border: none;
  padding: 20px;
  border-radius: 8px;
  margin: 10px 0px;
  width: 70%;
}
.acceptPolicy {
  color: #363648;
  margin-top: 10px;
  a {
    text-decoration: none;
  }
}
.contactUsHeading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  color: #363648;
  margin: 10px;
}
.dropDown {
  width: auto;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  color: #9a9aa3;
  width: 70%;
}
@media (max-width: 1100px) {
  .inputContainer {
    width: 90%;
    position: inherit;
  }
  .inputBoxText {
    width: 100%;
    min-width: none;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.04);
  }
  .dropDown {
    background: rgba(0, 0, 0, 0.04);
    width: 100%;
  }
  .inputBoxTextArea {
    width: 100%;
    background: rgba(0, 0, 0, 0.04);
  }

  .contactUsHeading {
    margin-left: 50px;
    padding-top: 10px;
  }
  .error1 {
    margin-left: 0;
  }
}

.submitBtn {
  background: linear-gradient(90.14deg, #00006e -72.99%, #5580e9 118.57%);
  border-radius: 25px;
  transition: "all 0.3s ease˳";
  color: white;
  height: 50px;
  width: 200px;
  border: none;
  margin-top: 20px;
}

.submitBtn:hover {
  background: white;
  color: DARKBLUE;
  border: 1px solid DARKBLUE;
}
