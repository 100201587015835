.impactWrapper {
  background-color: black;
  padding-top: 30px;
  margin-top: 4rem;
}

.impactWrapper_heading {
  color: rgba(255, 255, 255, 0.3);
  font-size: 100px;
  font-weight: 900;
  line-height: 126px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.borderedContainer {
  border: 1px solid rgba(226, 229, 232, 1);
  border-radius: 30px;
  position: relative;
}

.borderContainerImage {
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
}

.boxHeading {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}

.boxSubtext {
  color: rgba(154, 154, 163, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.impactService {
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
}

.numberWrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 15px 0;
}

.numberText {
  font-size: 60px;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.numberLabel {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: white;
  margin: 0;
}

.numberContainerDivider {
  border: 1px dashed rgba(255, 255, 255, 0.4);
}

.impactContainer {
  margin-top: 4rem;
  padding-bottom: 2.5rem;
}

.dashboardAndProdCard {
  padding: 80px 50px 10px;
}

@media (max-width: 1400px) {
  .dashboardAndProdCard {
    padding-top: 60px;
  } 
}

@media (max-width: 1200px) {
  .dashboardAndProdCard {
    padding: 45px 25px 5px;
  } 
  .numberText {
    font-size: 45px;
  }
  .numberLabel{
    font-size: 18px;
  }
  .impactDetails{
    gap: 0.5rem;
  }
}

@media (max-width: 992px) {
  .impactDetails, .impactDetailsRow {
    gap: 1rem;
  } 
}