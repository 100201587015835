.formContainer {
  display: flex;
  // background: linear-gradient(180deg, #e8f7fd 0%, #dff3fa 100%);
  width: 100%;
  align-items: center;
}
.bannerBackground {
  margin: 0% 10%;
  background: url("../../assets/images/bannerLines/white_lines.webp"),
    linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%) center cover
      no-repeat;
}

.contactUsBackgroundImage {
  background: url("../../assets/images/BackgrounImages/contact_us_bg.webp")
    no-repeat;
  height: 100vh;
  background-size: 125vw 100vh;
}

.contactUsCards {
  display: flex;
  justify-content: center;
  margin: 80px 0px;
}
.mobBackgroundImage {
  display: none;
}

.inputContainer {
  display: flex;
  justify-content: right;
}
.contactUsForm {
  justify-content: end;
}
@media (max-width: 1100px) {
  .contactUsBackground {
    background: none;
  }
  .contactUsBackgroundImage {
    display: flex;
    flex-direction: column;
    justify-content: start;
    min-height: 300px;
    // height: 300px;
    width: 100%;
  }
  .contactUsMain {
    display: none;
  }
  .formContainer {
    flex-direction: column;
    background: none;
  }
  .contactUsForm {
    display: flex;
    justify-content: center;
    // width: 50%;
  }
  .contactUsCards {
    flex-wrap: wrap;
    display: flex;
  }
}
