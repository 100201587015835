.nav {
  display: flex;
  align-items: center;
  justify-content: end;
}

.whiteBackground {
  background-color: white;
}

.navItems {
  font-size: 16px;
  font-weight: 400;
  color: black;
  min-width: 150px;
  text-align: center;
  justify-content: center;
  position: relative;
}

.navItemActive {
  color: rgba(0, 0, 110, 1) !important;

  &::after {
    content: "";
    border: 2px solid rgba(0, 0, 110, 1);
    border-radius: 20%;
    position: absolute;
    bottom: 0;
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__whiteBorder {
    &::after {
      border-color: white;
    }
  }
}

.logo {
  margin-left: 60px;
}
// Hover state product sub menu
.productsMenu {
  box-shadow: 5px 5px 25px 20px rgba(127, 135, 169, 0.05);
  margin-left: -220px;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 18px;
  position: absolute;
  display: grid;
  grid-template-columns: 260px 260px 270px;
  gap: 10px;
  background-color: white;
  z-index: 1000;
}

.selected {
  background-color: #f6f9fd;
  .headingClass {
    color: #0075f3;
  }
}

.menuHoverLink {
  text-decoration: none;
  color: black;
}
.menuHoverLink:hover {
  text-decoration: none;
  color: black;
}
.productsSubMenu {
  padding: 18px;
  margin: 4px;
  border-radius: 20px;
  display: flex;
  text-align: left;
  width: 270px;

  svg {
    height: 20px;
    width: 46px;
    margin: 2px 6px;
  }
}

@media (max-width: 993px) {
  .nav {
    align-items: normal;
    margin: 0;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .logo {
    margin: 16px 20px;
  }
  .navIcon {
    margin: 10px;
  }
  .navItems {
    margin: 10px;
  }
  .productsMenu {
    grid-template-columns: auto;
    position: unset;
    margin-left: 0px;
    background: inherit;
  }
  .productsSubMenu {
    width: auto;
  }
  .productsMenuIndustry {
    margin-top: 0px;
  }
  .addMarginTop {
    margin-top: 560px;
  }
}
.whiteText{
  color: green !important;
}
.mainContainerStyle {
  // position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: rgba(255, 255, 255, 0.108);
  backdrop-filter: blur(8px);
}

.whiteBtn {
  background: transparent;
  box-shadow: none;
  border: 1px solid #fff;
  color: #fff;
}

.blueBtn {
  background: transparent;
  box-shadow: none;
  border: 1px solid #00006E;
  color: #00006E;
}

@media (max-width: 768px) {
  .navbarStyle {
    padding: 0 !important;
  } 
}