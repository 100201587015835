.faqMain {
  margin: 45px 70px;
}
.faqQuestionContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.faqQuestion {
  font-size: 16px;
  font-weight: 400;
  color: #363648;
}
.faqPlus {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  transition: transform 0.3s ease-in-out;
}
.loadMoreMain {
  width: 100%;
  display: flex;
  justify-content: center;
}
.loadMoreButton {
  background: linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%);
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}
.rotate {
  transform: rotate(180deg);
}
.faqAnswer {
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 20px;
  max-height: 0;
  overflow: hidden;
  color: #9a9aa3;
  font-size: 14px;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.show {
  max-height: 1000px;
  animation: fade-in 0.5s ease-in-out;
}

.hide {
  max-height: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 567px) {
  .faqMain {
    margin: 25px 15px;
  }
}
