.useCaseMainContainer {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.usecaseTextContent {
  display: flex;
  padding: 40px;
}

.line {
  width: 268px;
  height: 1px;
  background-color: #e2e5e8;
}
.useCaseProductImageContainer {
  display: flex;
  flex-wrap: wrap;
  width: 700px;
}
.usecaseText {
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 50%;
}
.usecaseImage1 {
  border-radius: 30px;
}
.useCaseImage2 {
  border-radius: 30px;
}
@media (max-width: 767px) {
  .useCaseProductImageContainer {
    display: none;
  }
  .line{
    // width: auto;
  }
  .usecaseTextContent{
    justify-content: center;
  }
  .usecaseProductTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
