.mainContainer {
  background: no-repeat
    url("../../../assets/images/products/persona/persona.png");
  background-size: 100%;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.container {
  max-width: 100% !important;
}

.lockContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;
  padding-left: 60px !important;
  height: 100%;
}

.lockContainer img {
  height: 300px;
}

.lockTitle {
  line-height: 20px;
  bottom: 0;
  width: 100%;
  font-weight: 700;
  font-size: 50px;
  line-height: 63px;
  color: #ffffff;
  text-transform: uppercase;
}

.hambugerMenuIcon {
  position: absolute;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
  top: 20px;
  z-index: 9999;
}

.popoverContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
}

.popoverImageContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.popoverImageContainer img {
  width: 300px;
  height: auto;
}

.popoverKnowMore {
  // display: none;
  font-size: 18px;
  font-weight: 400;
  bottom: 10px;
  color: #ffffff;
  text-shadow: 1px 1px 3px #333333;
  a{
    text-decoration: none;
    color: #fff;
  }
}
.popoverKnowMore1 {
  display: block;
  padding-left: 15px;
  color: #ffffff;
  text-shadow: 1px 1px 3px #333333;
  margin-top: -30px;
}

.popoverKnowMore:hover {
  a{
    text-decoration:none;
    color: #fff;
    cursor: pointer;
  }
}

@keyframes slideUpText {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes slideDownText {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.cardsContainer {
  transform: translateX(-200px);

  > div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.cardsContainer2 {
  margin-top: 20px;
}

.halfWindow {
  height: 50vh;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.packedContent {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  color: #5580e9;
  overflow: hidden;
}

.expandedContent {
  width: 70%;
  position: relative;
  background: #c5d6ff;
  color: #21329e;
}
.verifyBoldText {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.personaTextVerify {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #eef3ff;
}
.knowMoreText {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: white;
}
.knowMoreText:hover {
  color: white;
  text-decoration: none;
}
.headingMain {
  color: #eef3ff;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
}
.headingSubMain {
  color: #eef3ff;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}
.realPersona {
  background: no-repeat
    url("../../../assets/images/products/persona/indiaMap.png") #5580e9;
  background-position: right;
}

.validationIdentity {
  background: no-repeat center
    url("../../../assets/images/products/persona/dots_and_circle.webp") #21329e;
  background-size: auto 90%;
  padding: 20px;
}

.gradStudent img {
  position: absolute;
  bottom: 0;
  margin-left: 50px;
}

.gradStudentContent {
  color: #c5d6ff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  text-align: right;
}

.boldText {
  font-weight: 500;
  font-size: 35px;
  line-height: 30px;
}

.plainText {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}

.zoopLogo {
  position: absolute;
  top: 0;
  right: 0;
}

.businessBootstingContent {
  margin: 40px;
  line-height: 40px;
  font-size: 24px;
}

.blueGraph {
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.whiteGraph {
  position: absolute;
  bottom: 0;
  z-index: 0;
}

.verificationLevelsContent {
  padding: 20px;
  text-align: right;
  line-height: 30px;
}

.verificationLevelsContent .plainText {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
}

.verificationLevelsImage {
  margin-top: 10px;
  height: 100%;
}

.verificationLevelsImage img {
  position: absolute;
  margin-top: -40px;
  right: 20px;
  width: auto;
}

/**  -------- Details Page ---------- **/

.detailsMainContainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  position: relative;
}

.sideBarContainer {
  color: white;
  background: #5580e9;
  width: 25vw;
  height: 100vh;
  padding: 30px;
  position: fixed;
  top: 0;
  left: 0;
}

.contentMainContainer {
  width: 75vw;
  margin-left: 25vw;
}

.menuItemsContainer {
  margin-top: 20px;
  line-height: 50px;
}

.menuLinks a {
  text-decoration: none;
  color: white;
}

.contentMainContainer {
  padding: 30px;
}

.contentTitle {
  font-family: Outfit;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  color: #363648;
  margin-bottom: 40px;
  width: 70%;
}

.contentInfo {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #999999;
  margin-bottom: 30px;
  width: 70%;
}

.contentSubTitle {
  font-family: Outfit;
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #363648;
  margin-bottom: 30px;
  width: 70%;
}

.profilePicsContainer {
  justify-content: start;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.profilePicsCard {
  width: 150px;
  text-align: center;
}

.profilePicsCard img {
  width: 100px;
  height: 100px;
  border-radius: 70px;
}

.profilePicsCard .title {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 15px;
}

.profilePicsCard .subTitle {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #989898;
}
.ZoopLogo {
  position: absolute;
  z-index: 9999;
  padding: 30px;
}
.homeLogo {
  position: absolute;
  z-index: 9999;
  padding: 10px;
}
.logoLink {
  text-decoration: none;
}
.active {
  color: #0f9d58 !important;
}

.personImage {
  position: absolute;
  bottom: 0;
  height: 50%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .sideBarContainer {
    display: none;
  }
  .contentMainContainer {
    padding: 10px;
    width: auto;
    margin: 0;
  }
  .contentInfo {
    width: 100%;
  }
  .profilePicsContainer {
    width: auto;
  }
  .profilePicsCard {
    width: 70px;
    margin: 2px;
    img {
      height: 50px;
      width: 50px;
    }
  }
  .contentTitle {
    width: 100%;
  }
  .title {
    font-size: 10px !important;
  }
  .mainContainer {
    background: linear-gradient(
      90deg,
      #1f4590 0%,
      #224996 22.12%,
      #274e9c 49.33%,
      #244b9a 81.87%,
      #224a99 92.56%,
      #204998 100%
    );
  }
  .popoverContainer {
    z-index: 1;
    height: unset;
    padding: unset !important;
  }
  .cardsContainer {
    transform: translateY(-80px);
    padding: 0 15px;
  }
  .lockContainer {
    padding-left: 0 !important;
    align-items: center;

    img {
      height: 200px;
    }
  }
  .lockTitle {
    text-align: center;
    font-size: 24px;
  }
  .popoverImageContainer {
    img {
      width: 100%;
      height: auto;
    }
  }
  .packedContent,
  .expandedContent {
    width: 100%;
  }
  .realPersona {
    background: rgba(85, 128, 233, 1);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    .headingSubMain {
      font-size: 18px;

      .headingMain {
        font-size: 30px;
      }
    }

    .personaTextVerify {
      font-size: 18px;
    }

    img {
      width: 46%;
      height: auto;
    }
  }
  .businessBootstingContent {
    margin: 15px 15px 80px;
    font-size: 18px;
  }
  .graphContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .expandedContent {
    display: flex;
    flex-direction: row;
  }
  .validationIdentity {
    padding-bottom: 0;
  }
  .gradStudent {
    display: flex;
    align-items: flex-end;
    img {
      width: 100%;
      height: auto;
      position: unset;
      margin-left: 0;
    }
  }
  .gradStudentContent {
    font-size: 18px;
    position: unset;
    padding-bottom: 20px;

    .boldText:first-child {
      font-size: 18px;
      text-transform: lowercase;
      font-weight: normal;
    }
  }
  .halfWindow {
    height: unset;
  }
  .verificationContent {
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    padding: 15px;

    .verificationLevelsContent {
      flex: 1;
    }

    .verificationLevelsImage {
      height: unset;
      flex: 1;
      margin-top: 0;
      position: absolute;
      left: 0;

      img {
        width: 100%;
        height: 200px;
        position: unset;
        margin: 0;
        right: 0;
      }
    }
  }
}
