.btnContent{
    display: flex;
    padding: 14px 5px;
    border-radius: 10px;
    cursor: pointer;
}

.buttonImg{
    margin-right: 5px;
    height: 20px;
    width: 20px;
}

.btnText{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}