.businessCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.bannerBackground {
  background: url("../../assets/images/bannerLines/white_lines.webp"),
    linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%);
  background-size: cover;
}
.productVerifyContainer {
  height: 768px;
  display: flex;
}

.digitalContractingBackground {
  background: url("../../assets/images/BackgrounImages/digital_contracting_bg.webp")
    no-repeat;
  height: 100vh;
  background-size: 100vw 100vh;
}
.mainImage {
  width: 100%;
  height: 340px;
  margin: 10px;
}
.image1 {
  margin: 10px;
  width: 30%;
  margin: 10px;
}
@media (max-width: 767px) {
  .digitalContractingBackground {
    background: none;
  }
  .productVerifyContainer {
    margin-top: 80px;
    display: block;
  }
}

.productVerify {
  margin-top: 10px;
  margin-bottom: 80px;
}
