.statsCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.statsCard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 350px;
  background: linear-gradient(180deg, #007AFF 0%, #0055B1 100%);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  color: white;
  position: relative;
  overflow: hidden;
  z-index: 0;
  justify-content: center;
}

.statsCardTransparent {
  background: transparent;
  border-radius: 0;
}

.statsCardImage {
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.statsCardImage img {
  width: 60px;
}

.statsCardTitle {
  font-size: 50px;
  font-weight: 600;
  line-height: 80px;
  z-index: 0;
}

.statsCardSubTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  // width: max-content;
}

.dancingBarsContainer {
  position: absolute;
  height: 150px;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

@media (max-width: 1024px) {
  .statsCardTitle { 
    font-size: 50px;
    font-weight: 600;
    line-height: 40px;
  }

  .statsCardSubTitle {
    font-size: 16px;
    width: unset;
  }
}

@media (max-width: 768px) {
  .statsCard {
    // width: 40%;
    // margin: 10px;
    height: 300px;
  }  
}

@media (max-width: 480px) {
  .statsCard {
    height: 250px;
  }
  .statsCardTitle {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  .statsCardSubTitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
  }
}
