.thankYouContainer{
    text-align: center;
    margin-top: 15%;
}
.textContainer{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
}
.redirectText{
    position: absolute;
    width: 100%;
    bottom: 10px;
    color: blue;
}