@import "../assets/scss/variables.scss";

:global {
  .display_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-30;
    line-height: 38px;
    display: flex;
    align-items: center;

    color: $black-color;
  }

  .heading_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 700;
    font-size: $d-font-24;
    line-height: 30px;
    display: flex;
    align-items: center;

    color: $black-color;
  }

  .title_bold_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 600;
    font-size: $d-font-18;
    line-height: 28px;
    // display: flex;
    // align-items: center;

    color: $black-color;
  }

  .title_semibold_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 600;
    font-size: $d-font-18;
    line-height: 23px;

    color: $black-color;
  }

  .body_heading_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 600;
    font-size: $d-font-16;
    line-height: 20px;

    color: $black-color;
  }

  .body_regular_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: $black-color;
  }

  .body_small_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: $black-color;
  }

  .overline_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: $text-secondary-color;
  }

  .overline_strong_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 700;
    font-size: $d-font-14;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: $text-secondary-color;
  }

  .caption_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-12;
    line-height: 15px;
    display: flex;
    align-items: center;

    color: $black-color;
  }

  .card_title_bold_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 500;
    font-size: $d-font-18;
    line-height: 28px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: $black-color;
  }

  .card_title_semibold_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 600;
    font-size: $d-font-18;
    line-height: 28px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: $primary-color;
  }

  .link_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 500;
    font-size: $d-font-14;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;

    color: $primary-color;
  }

  .table_header_text {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-16;
    line-height: 18px;
    display: flex;
    align-items: center;

    color: #a0a2a5;
  }

  .card_button {
    background-color: $lite-accent-color;
    font-size: $font-14;
    color: $accentColor;
    font-weight: 500;
    border-radius: 8px;
    height: 40px;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
    }
  }

  .scrollable {
    overflow: scroll;
    height: 200px;
  }

  .scrollable-mini {
    overflow: scroll;
    height: 110px;
    display: block;
  }

  .collapse_button {
    position: absolute;
    z-index: 1;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    background: linear-gradient(180deg, #24a5c6 0%, #2d89ec 100%);
    color: white;
    outline: none;
    border: none;
    position: absolute;

    @media (min-width: 2559px) {
      left: 95%;
    }

    left: 14vw;
    font-weight: 600;

    &:focus {
      outline: none;
      border: none;
    }
  }

    .back_button {
      z-index: 1;
      height: 32px;
      width: 32px;
      border-radius: 30px;
      background: linear-gradient(180deg, #24a5c6 0%, #2d89ec 100%);
      color: white;
      outline: none;
      border: none;
  
      @media (min-width: 2559px) {
        left: 95%;
      }
  
      left: 14vw;
      font-weight: 600;
  
      &:focus {
        outline: none;
        border: none;
      }
    }
  .collapse_button_active {
    position: absolute;
    z-index: 1;
    height: 32px;
    width: 32px;
    border-radius: 30px;
    background: linear-gradient(180deg, #24a5c6 0%, #2d89ec 100%);
    color: white;
    outline: none;
    border: none;
    position: absolute;

    @media (min-width: 2559px) {
      left: 95%;
    }

    left: 4vw;
    font-weight: 600;

    &:focus {
      outline: none;
      border: none;
    }
  }

  // Background colors classes
  .bg-background-white-color {
    background-color: $background-white-color !important;
  }

  .bg-lite-accent-color {
    background-color: $lite-accent-color !important;
  }

  .bg-normal-accent-color {
    background-color: $normal-accent-color !important;
  }

  .bg-primary-color {
    background-color: $primary-color !important;
  }

  .bg-lite-secondary-color {
    background-color: $lite-secondary-color !important;
  }

  .bg-text-secondary-color {
    background-color: $text-secondary-color !important;
  }

  .bg-chip-yellow-color {
    background-color: $chip-yellow-color !important;
  }

  .bg-lite-green-color {
    background-color: $lite-green-color !important;
  }

  .bg-normal-green-color {
    background-color: $normal-green-color !important;
  }

  .bg-normal-blue-color {
    background-color: $normal-blue-color !important;
  }

  .bg-black-color {
    background-color: $black-color !important;
  }

  .bg-normal-orange-color {
    background-color: $normal-orange-color !important;
  }

  .bg-lite-orange-color {
    background-color: $lite-orange-color !important;
  }

  .bg-strong-orange-color {
    background-color: $strong-orange-color !important;
  }

  .bg-diable-color {
    background-color: $diable-color !important;
  }

  .bg-white-color {
    background-color: $white-color !important;
  }

  // Colors classes
  .background-white-color {
    color: $background-white-color !important;
  }

  .lite-accent-color {
    color: $lite-accent-color !important;
  }

  .normal-accent-color {
    color: $normal-accent-color !important;
  }

  .primary-color {
    color: $primary-color !important;
  }

  .lite-secondary-color {
    color: $lite-secondary-color !important;
  }

  .text-secondary-color {
    color: $text-secondary-color !important;
  }

  .chip-yellow-color {
    color: $chip-yellow-color !important;
  }

  .lite-green-color {
    color: $lite-green-color !important;
  }

  .normal-green-color {
    color: $normal-green-color !important;
  }

  .normal-blue-color {
    color: $normal-blue-color !important;
  }

  .black-color {
    color: $black-color !important;
  }

  .normal-orange-color {
    color: $normal-orange-color !important;
  }

  .lite-orange-color {
    color: $lite-orange-color !important;
  }

  .white-color {
    color: $white-color !important;
  }

  .css-tlfecz-indicatorContainer {
    padding: 2px !important;
  }

  .is-top-overflowing {
    --top-mask-size: 48px;
  }

  .is-bottom-overflowing {
    --bottom-mask-size: 250px;
  }

  .fading-effect-table {
    overflow-y: auto;
    -webkit-mask-image: linear-gradient(
      to bottom,
      transparent 0,
      black var(--top-mask-size, 0),
      black calc(100% - var(--bottom-mask-size, 0)),
      transparent 100%
    );
    mask-image: linear-gradient(
      to bottom,
      transparent 0,
      black var(--top-mask-size, 0),
      black calc(100% - var(--bottom-mask-size, 0)),
      transparent 100%
    );
  }

  .border-radius-10 {
    border-radius: 10px !important;
  }

  .add-batch-modal {
    .modal-content {
      border-radius: 20px !important;
    }
  }

  @media screen and (max-width: 1350px) {
    .title_bold_text {
      font-size: $d-font-16 !important;
    }
  }

  .customTooltip {
    .tooltip-inner {
      background: $warning-red !important;
      color: $danger !important;
      background-color: $warning-red !important;
      max-width: fit-content;
    }

    .tooltip.show {
      opacity: 1 !important;
    }

    .arrow::before {
      border-bottom-color: $warning-red !important;
      color: $warning-red !important;
      // background: $danger !important;
    }
  }
}
