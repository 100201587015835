@import "../../../assets/scss/variables.scss";

.homeInfoMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 70px;
  color: $white;
  width: 700px;
}

.footeBackgroundColor {
  background-color: #031721;
}
.retailMarqueecolor {
  border: 5px solid rgb(0, 21, 30) !important;
  margin-bottom: 0;
  z-index: inherit;
}
.retailMarqueeL {
  background: linear-gradient(
    90deg,
    rgba(0, 21, 30, 1) 30%,
    rgba(0, 21, 30, 0) 100%
  );
}
.retailMarqueeR {
  background: linear-gradient(
    270deg,
    rgba(0, 21, 30, 1) 30%,
    rgba(0, 21, 30, 0) 100%
  );
}
/* ---- Full Bleed Container ----- */

.fullBleedContainer {
  background: black
    url("../../../assets/images/BackgrounImages/retail_bg.webp") no-repeat center;
  background-size: 110vw auto;
  height: 100vh;
}

.brandMarqueeContainer {
  // height: 200px;
  padding: 30px 0;
  width: 100vw;
  background-color: #00151e;
}

.image3 {
  width: 100%;
}
.gamingBackground {
  width: 100vw;
  height: auto;
  left: 0px;
  top: 768px;
  background: linear-gradient(180deg, #011620 0%, #9B644A 53.98%, #021721 100%);
}

.dividerContainer {
  margin: auto;
  width: 100vw;
  text-align: center;
}

.retailMainContainer {
  padding: 60px;
}
.retailSection1 {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.businessImage {
  flex: 1;

  img {
    width: 100%;
  }
}
.retailSection3Image {
  width: 140%;
}

.retailTextHeading {
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  background: #FFE7DA;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.retailTextContent {
  font-weight: 300;
  font-size: 22px;
  line-height: 34px;
  color: #FFFFFF;
  margin-top: 20px;
}
.impactBackground {
  background: transparent;
  margin: 0px;
  border-radius: 0px;
  height: 50%;
}
.impactHeading {
  display: none;
}
.retailImpactCard {
  margin-top: 100px;
  position: sticky;
}
.retailTextImage {
  margin-top: -230px;
  display: flex;
  justify-content: end;
  margin-bottom: 30px;
}
.retailSectionTextContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.retailSection2 {
  display: flex;
  justify-content: center;
  text-align: center;
}
// .retailSectionTextContainer2 {
//   width: 50%;
// }
.retailSection4 {
  margin-top: 0px !important;
  // padding: 60px;

  img {
    width: 100%;
    height: auto;
  }
}

.bannerBackGroundcolor {
  background: white;
  background-image: url("../../../assets/images/bannerLines/brown_lines.webp"),
    linear-gradient(90deg, #9B644A 16.22%, #9B644A00 100%);
  background-size: cover;
}

.bannerHeading,
.bannerTickText {
  color: #fff;
}

@media (max-width: 1400px) {
  .fullBleedContainer {
    background-size: 150vw auto;
  }
}

@media screen and (max-width: 1200px) {
  .fullBleedContainer {
    background-size: 160vw auto !important;
  }
}

@media (max-width: 400px) {
  .fullBleedContainer {
    background-size: 180vw auto !important;
  }
}

@media (max-width: 767px) {
  .fullBleedContainer {
    background: url("../../../assets/images/BackgrounImages/retail_bg_mobile.png")
        no-repeat bottom,
      linear-gradient(180deg, #000000 0%, #00151f 100%);
    background-size: 100vw auto !important;
  }
  .heading1 {
    font-size: 32px;
    font-weight: 700;
    margin-top: 100px;
  }
  .homeInfoContent {
    font-size: 12px;
    font-weight: 400;
  }
  .homeInfoMainContainer {
    padding: 10px;
    width: auto;
    justify-content: start;
  }
  .retailTextHeading {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }
  .retailSectionTextContainer {
    // padding: 50px;
    width: auto;
    text-align: center;
  }
  .retailSection1 {
    padding: 20px;
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .retailTextContent {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
  .retailSectionTextContainer2 {
    width: auto;
  }
  .retailTextContent2 {
    width: auto;
  }
  .retailSection1 {
    gap: 10px;
    padding: 0;
  }
  .retailImpactCard {
    margin-top: 0;
  }
  .retailSection3 {
    margin-top: 20px;
  }
  .retailSection4 {
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: 50px !important;
  }
  .retailTextImage {
    display: none;
  }
  .image1,
  .image2,
  .image3,
  .image4,
  .image5 {
    width: 100%;
    margin: 0;
  }

  .retailMainContainer{ 
    padding: 30px;
  }
}

@media (max-width: 567px) {
  .brandMarqueeContainer {
    height: fit-content;
    padding: 50px 0;
  }
}

@media (min-width: 1920px){
  .gamingBackground{
    width: auto;
  }
}
