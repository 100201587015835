.productVerifyMainContainer {
  display: flex;
  align-items: center;
  // margin: 60px 0px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 70px 70px 70px;
  color: white;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.productVerifyContent {
  display: flex;
  justify-content: space-between;
}

.productVerifyHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 86px;
  color: #363648;
  width: 50%;
}

.productVerifyContentText {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #202020;
  width: 40%;
  margin-top: 2px;
}
.productVerifyTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productVerifyImageContainer {
  display: none;
}

@media (max-width: 767px) {
  // .main{
  //   margin-top: 750px;
  // }
  .productVerifyImageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
    background: linear-gradient(to top, rgba(247,247,49,1) 0%,rgba(255,255,255,0) 69%);

    img{
      margin-top: 50px;
      height: 400px;
      width: auto;
      min-width: 300px;
    }
  }
  .productVerifyMainContainer {
    background: linear-gradient(
      180.95deg,
      #ffffff 1.6%,
      #ffffff 47.96%,
      rgba(255, 255, 255, 0.72) 72.65%,
      rgba(255, 255, 255, 0) 98.35%
    );
    padding: 10px 15px;
  }
  .productVerifyContentText, .productVerifyHeading{
    width: 100%;
  }

  .productVerifyTextContainer {
    width: auto;
    justify-content: start;
    background: linear-gradient(to bottom, white, transparent);
  }

  .productVerifyHeading {
    position: relative;
    margin-top: 0;
    font-size: 32px;
    line-height: 80px;
  }
}

.mainBtn {
  background: linear-gradient(90.14deg, #00006e -72.99%, #5580e9 118.57%);
  border-radius: 25px;
  transition: "all 0.3s ease˳";
  color: white;
  border: none;
  margin-top: 20px;
}

.mainBtn:hover {
  background: white;
  color: DARKBLUE;
  border: 1px solid DARKBLUE;
}
