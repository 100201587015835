.footerMain {
  background-color: #363648;
  color: white;

  /* Add more CSS for background, spacing, etc. */

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the top horizontally */
    text-align: left; /* Align text to the left */
  }
  // @media (max-width: 1400px) {
  //   flex-direction: row;
  //   padding: 30px;
  //   justify-content: space-between;
  //   align-items: flex-start; /* Align items at the top horizontally */
  //   text-align: left; /* Align text to the left */
  // }
}

.footerMainContent {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  // gap: 100px;
  width: 100%;
  // max-width: 1200px; /* Adjust this value as needed */
}

.footerZoopText {
  font-size: 14px;
  font-weight: 100;
}

.footerZoopTextMain {
  margin-top: 15px;
  display: flex;
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  width: 300px;
}

.footerDetailHeading {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.footerLink {
  // text-transform: capitalize;
  font-weight: 100;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: #f7f8fd;
}
.linkDiv {
  margin-bottom: 7px;
}
.footerLink:hover {
  text-decoration: none;
  color: white;
}
.partialBorder {
  height: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 15px;
}
.partialBorder:after {
  content: "";
  width: 20px;
  height: 2px;
  background: white;
  position: absolute;
  border-radius: 10px;
}

.footerDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  width: min-content;
}

.footerMenu {
  display: flex;
  margin-bottom: 50px;
}
.nestedMenu {
  margin-bottom: 70px;
}
.footerDetailContent {
  padding: 0px 15px;
  width: 270px;
}

.footerCompanyContent {
  border-top: 1px solid #8c95bb;
  opacity: 40%;
  color: #8c95bb;
  padding: 30px 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footerFeatureName {
  font-weight: 400;
  color: #8c95bb;
}
.footerCompanyName {
  opacity: 100%;
  font-weight: 400;
}
.zoopVertical {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.footerFeatureName:hover {
  color: #8c95bb;
}

.footerDefinedWidth {
  // width: 300px;
}

@media (max-width: 768px) {
  .footerMenu {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .nestedMenu {
    margin-bottom: 0px;
  }
  .footerDetailsContainer {
    flex-wrap: wrap;
    width: max-content;
    padding-left: 6%;
  }
  .footerDetailContent {
    width: 100%;
    padding: 10px 5px;
  }
  .footerMainContent {
    flex-wrap: wrap;
    padding: 50px;
  }
  .footerFeatureContainer {
    margin-top: 15px;
    width: 100%;
    // justify-content: space-between;
  }
  .footerCompanyContent {
    justify-content: center;
    padding: 20px 0px;
  }
}

@media (max-width: 567px) {
  .footerMainContent {
    padding: 50px 20px 20px;
    gap: 0;
  }
  .footerDetailContent {
    margin: 0;
  }
}
