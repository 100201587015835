.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(95.13deg, #5580e9 -3.16%, #00006e 145.27%);
  padding: 60px 80px;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  color: white;
}
.headerDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #eaeaea;
}

.categoryHeading {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}
.headingContainer {
  display: flex;
}
.headingContainer2 {
  display: flex;
  margin-top: 100px;
}
.line {
  width: 152px;
  background-color: black;
  height: 1px;
  // margin-top: 15px;
  margin: 15px;
}
.line1 {
  width: 120px;
}
.relatedPostDetail {
  display: flex;
}
.relatedPostImageContainer {
  img {
    width: 84px;
    height: 60px;
    border-radius: 8px;
  }
  margin: 5px;
}
.postHeading {
  margin: 5px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}
.postContent {
  margin: 5px;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  color: #bfbfbf;
}
.headerImage {
  flex: 4;
  img {
    width: 100%;
    height: 445px;
    background-color: gray;
    border-radius: 20px;
  }
}
.detailText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.detailTextContainer {
  width: 95%;
  margin-top: 50px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
.tagsDetailContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.pageTagContainer {
  margin-top: 30px;
}
.questionText {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 50px;
}
.answerText {
  margin-top: 20px;
}
.tagsDetail {
  border: 1px solid #c7c7c7;
  padding: 10px;
  //   margin: 5px;
  text-transform: capitalize;
}
.categoryContainer {
  flex: 1;
  margin: 0px 75px;
}
.categoryDetail {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  text-transform: capitalize;
}
.categoryDetailContainer {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
}
.section1 {
  display: flex;
  margin: 80px;
  // flex-wrap: wrap;
}

@media (max-width: 567px) {
  .mainHeader {
    padding: 30px 40px;
  }
  .heading {
    text-align: center;
    font-size: 20px;
  }
  .headerDate {
    text-align: center;
    margin-top: 5px !important;
  }
  .section1 {
    flex-direction: column;
    margin: 40px 20px;
  }
  .categoryContainer {
    margin: 20px 0 0;
  }
  .categoryHeading {
    font-size: 20px;
  }
  .line {
    flex: 1;
  }
  .categoryDetail {
    font-size: 16px;
  }
}
