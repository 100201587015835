.isoBannerMainContainer {
  justify-content: space-between;
  display: flex;
  padding: 100px 80px !important;
  border: 1px solid #e2e5e8;
  border-radius: 30px;
}
.isoImages {
  width: 60%;
  justify-content: space-between;
  display: flex;
}
.isoContainer {
  display: flex;
  justify-content: center;
  padding: 0px 0px 45px 0px;
  background: black;
}
.isoTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}
.isoText {
  width: 50%;
  color: white;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
