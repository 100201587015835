@import "../../../assets/scss/variables.scss";

.homeInfoMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 70px;
  color: $white;
  width: 700px;
}

.retailMarqueecolor {
  border: 5px solid rgb(0, 21, 30);
  z-index: inherit;
}
.retailMarqueeL {
  background: linear-gradient(
    90deg,
    rgba(0, 21, 30, 1) 30%,
    rgba(0, 21, 30, 0) 100%
  );
}
.retailMarqueeR {
  background: linear-gradient(
    270deg,
    rgba(0, 21, 30, 1) 30%,
    rgba(0, 21, 30, 0) 100%
  );
}
.impactBackground {
  background: transparent;
  margin: 0px;
  border-radius: 0px;
}
.impactHeading {
  display: none;
}
.retailImpactCard {
  position: sticky;
  margin-bottom: 100px;
}
.visitBtn {
  border: none;
  outline: 0 !important;
  font-size: 14px;
  cursor: pointer;
  color: $secondaryColor;
  background-color: $white;

  &:hover {
    color: $accentColor;
  }
}

@media (max-width: 576px) {
  .homeInfoMainContainer {
    justify-content: start;
    padding: 100px 10px;
    width: auto;
  }
}

/* ---- Full Bleed Container ----- */

.fullBleedContainer {
  background: black
    url("../../../assets/images/BackgrounImages/gaming_bg.webp") no-repeat center;
  background-size: 110vw auto;
  height: 100vh;
}

@media (max-width: 1400px) {
  .fullBleedContainer {
    background-size: 150vw auto;
  } 
}

.footeBackgroundColor {
  background-color: #031721;
}
.brandMarqueeContainer {
  height: 200px;
  width: 100vw;
  background-color: #00151e;
}

.gamingBackground {
  width: 100vw;
  height: auto;
  left: 0px;
  top: 768px;

  background: linear-gradient(180deg, #00141c 0%, #3b1c8b 53.98%, #031721 100%);
}

.dividerContainer {
  margin: auto;
  width: 100vw;
  text-align: center;
}

.bannerBackGroundcolor {
  background: white;
  background-image: url("../../../assets/images/bannerLines/lines3-blue.webp"),
    linear-gradient(90deg, #41239C 16.22%, #41239C59  100%);
  background-size: cover;
}

.bannerHeading,
.bannerTickText {
  color: #fff;
}

.reinventHeadingContainer {
  padding-top: 100px;
  color: white;
  text-align: center;
}
.reinventHeading {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.reinventContent {
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  margin-top: 10px;
}
.section1 {
  display: flex;
  // flex-wrap: wrap;
  margin-top: 100px;
  padding: 80px;
  flex-direction: row;
  justify-content: space-between;
  .sectionHeading{
    width: 180%;
  }
  .sectionContent{
    width: 150%;
  }
}
.sectionHeading {
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  background: linear-gradient(95.13deg, #c0d3ff -3.16%, #fdfdfd 145.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 20px;
}
.sectionContent {
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: #a1bcff;
  margin-bottom: 20px;
}
.sectionTextContainer {
  // position: absolute;
  width: 50%;
  margin-top: 200px;
}
.sectionImage1 {
  margin-left: -140px;
  margin-top: -220px;
}

.section2 {
  display: flex;
  padding: 80px;
  margin-left: 30px;
  margin-top: 100px;
}
.sectionTextContainer2 {
  margin-top: 170px;
  margin-left: 500px;
  position: absolute;
  width: 50%;
  text-align: end;
  .sectionContent {
    text-align: justify;
    width: 80%;
    margin-left: 150px;
  }
}
.section2Line3 {
  position: absolute;
  height: 400px;
  margin-left: 550px;
  margin-top: -300px;
}
.section2Images {
  display: flex;
  flex-direction: column;
}
.section3 {
  margin-top: 200px;
  display: flex;
  padding: 80px;
  .sectionContent {
    width: 90%;
  }
}
.section3Line2 {
  position: absolute;
  height: 500px;
  margin-top: -600px;
  margin-left: -340px;
}
.section4 {
  margin-left: 100px;
  display: flex;
  padding: 80px;
  margin-top: 10px;
  .sectionHeading {
    width: 85%;
    margin-left: -100px;
    text-align: center;
  }
  .sectionContent {
    margin-left: -150px;
    // width: 95%;
  }
}
.section4Line3 {
  position: absolute;
  margin-left: 400px;
  margin-top: -380px;
  height: 500px;
}
.sectionTextContainer4 {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
@media (max-width: 767px) {
  .fullBleedContainer {
    background: black
    url("../../../assets/images/BackgrounImages/gaming_bg_mobile.png") no-repeat center;
    background-size: cover;
  }
  .brandMarqueeContainer{
    height: fit-content;
    padding: 50px 0;
  }
  .retailMarqueecolor{
    width: 100%;
    padding: 0;
  }
  .heading1{
    font-size: 32px;
    font-weight: 700;
    margin-top: 100px;
  }
  .homeInfoContent{
    font-size: 12px;
    font-weight: 400;
  }
  .homeInfoMainContainer{
    padding: 10px;
    width: auto;
    justify-content: start;
  }
  .reinventHeadingContainer {
    padding: 40px;
  }
  .reinventHeading {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  .reinventContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
  .section1 {
    padding: 10px;
    justify-content: center;
    text-align: center;
    flex-direction: column-reverse;
    .sectionContent {
      width: auto;
    }
    .sectionHeading{
      width: auto;
    }
    .sectionTextContainer {
      width: 100%;
      margin-top: 0px;
      position: relative;
    }
    .sectionImage1 {
      margin-left: -180px;
      margin-top: 0;
      img {
        height: 350px;
        width: auto;
      }
    }
  }

  .sectionHeading {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    padding: 40px 40px 0px 40px;
  }
  .sectionContent {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0px 40px 0px 40px;
  }
  .section2 {
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px;
    margin-top: -100px;
    .section2Images {
      width: 100%;
    }
    .section2Line3 {
      position: relative;
      height: 300px;
      margin: auto;
    }
    .sectionTextContainer2 {
      position: relative;
      margin: 0;
      width: auto;
      .sectionHeading,
      .sectionContent {
        width: auto;
        margin: 0;
        text-align: center;
      }
    }
    .sectionImage2 {
      display: flex;
      justify-content: center;
      img {
        height: 390px;
        width: auto;
      }
    }
  }

  .section3 {
    margin-top: 0px;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: -55px;
    text-align: center;
    flex-direction: column-reverse;
    .sectionContent {
      width: auto;
    }
    .section3Line2 {
      position: relative;
      height: 300px;
      width: auto;
      margin: 0;
    }
    .section3LineContainer {
      width: 100%;
      display: flex;
      justify-content: end;
    }
    .sectionImage3 {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        height: 300px;
      }
    }
  }

  .section4 {
    flex-wrap: wrap;
    margin: 0;
    padding: 10px;
    margin-top: -55px;
    .sectionTextContainer4 {
      margin: 0;
    }
    .sectionHeading,
    .sectionContent {
      margin: 0;
      text-align: center;
      width: 100%;
    }
    .section4Line3 {
      position: relative;
      margin-left: 0px;
      margin-top: 0px;
      height: 300px;
      width: 320px;
    }
    .section4Images {
      width: 100%;
    }
    .sectionImage4 {
      display: flex;
      justify-content: center;
      width: 100%;
      // margin-left: 50px;
      img {
        height: 300px;
      }
    }
  }
}

@media (max-width: 567px) {
  .reinventHeadingContainer{
    margin-top: 0;
  }

  .impactCardsContainer {
    width: 80% !important;
  }
}

@media(min-width: 1920px){
  .gamingBackground{
    width: auto;
  }
}