body {
  margin: 0;
  font-family: "Outfit", sans-serif !important;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

html {
  /* overflow-y: scroll;
  scrollbar-color: #f5f5f5 #dfdfdf;
  scrollbar-width: thin; */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 2px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #747474;
}
