.sidebarMain {
  overflow: scroll;
  width: 300px;
  background-color: white;
  right: 0;
  height: 100vh;
  margin-top: -45px;
  margin-right: -20px;
  padding: 10px 20px;

  &.fullScreen {
    width: 100%;
    margin: 0;
    position: fixed;
    z-index: 9999;
  }
}

.blueBackground {
  background-color: rgba(10, 9, 113, 1);

  .sidebarMenu {
    color: white;
  }

  .sidebarMenuActive {
    color: white;
    font-weight: 600;
  }

  .menuHoveLink {
    color: white;

    &:hover {
      color: inherit !important;
    }
  }

  .selected {
    svg {
      path {
        fill: #0075f3;
      }
    }

    > div {
      color: #0075f3;
    }
  }
}

.menuHoveLink {
  text-decoration: none;
  color: black;
  border-radius: 8px;
}
.menuHoveLink:hover {
  text-decoration: none;
  color: #5d546a;
}
.selected {
  border-radius: 8px;
  background-color: #f6f9fd;
  .headingClass {
    color: #0075f3;
  }
}
.sidebarMenu {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  align-items: center;
}
.sidebarMenuActive {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0a0971;
  align-items: center;
}

.menuOpen {
  rotate: 180deg;
  transition: all 0.5s ease-in;
}
.sidebarSubMenu {
  padding: 20px 0px;

  svg {
    height: 20px;
    width: 30px;
    margin: 2px 10px;
  }
}
