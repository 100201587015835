.mainCelendlyContainer {
  background-color: #cddbff;
  display: flex;
  justify-content: space-between;
  padding: 80px;
  border-radius: 20px;
  overflow: hidden;
}

.textCalendly {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.calendlyHeading {
  width: max-content;
  font-weight: 700;
  font-size: 34px;
  line-height: 43px;
  color: #293da8;
}

.calendlyContent {
  width: max-content;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
}

.btn {
  margin-top: 20px;
}

.bookingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bookingText {
  margin-bottom: -55px;
  font-weight: 700;
  font-size: 34px;
  line-height: 43px;
  text-align: center;
  color: #293da8;
}

.calendlyCalendar {
  margin-left: -65px;
}

@media (max-width: 768px) {
  .mainCelendlyContainer {
    padding: 40px 20px;
  }

  .calendlyHeading {
    font-size: 28px;
  }
  .calendlyContent {
    font-size: 18px;
  }
}
