.mainProductVerify {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.productVerifyImage {
  img {
    width: 100%;
  }

  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
}

.points {
  display: flex;
}
.productVerifyTextMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productVerifyBullet {
  margin-right: 5px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #5d546a;
}
.productVerifyPoints {
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #5d546a;
  width: 360px;
}

@media (max-width: 767px) {
  .mainProductVerify {
    padding: 10px;
  }
  .productVerifyTextMain {
    padding: 10px;
    margin: 0;

    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .subHeading {
      width: 100%;
    }
  }
  .productVerifyImage {
    img {
      width: 100%;
      height: auto;
    }
  }
}
