$primaryColor: #202020;
$secondaryColor: #747474;
$accentColor: #0075f3;
$accentHoverColor: #004f9f;

$warningBackground: #fffde0;
$inputBorderColor: rgba(112, 112, 112, 0.5);
$inputBackgroundColor: #ffffff;
$cardHeaderColor: #dfdfdf;
$cardBackGround: linear-gradient(90deg, #BCCFFF 16.22%, #FFFFFF 100%);
$annimationName: slideTransition;

$CardboxShadow: 0 3px 6px 0 rgba(221, 221, 221, 0.16);

$firaSans: "Fira Sans", sans-serif;
$Outfit: "Outfit";

$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;

@keyframes slideTransition {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// New Dashboard revamp variables

// Colors
$dark-blue: #00006E;
$dark-grey: #9A9AA3;
$dark-purple: #363648;
$white: #fff;
$light-blue: #5D86EA;
$hr-line-color: #E2E5E8;
$status-line-color: #F6F9FD;
$selected-box: #E0ECFD;
$selected-box-border: #D2E6FF;
$background-white-color: #f5f9ff;
$lite-accent-color: #cbe4ff;
$normal-accent-color: #66b0ff;
$primary-color: #0075f3;
$lite-secondary-color: #ebf4fe;
$text-secondary-color: #969696;
$chip-yellow-color: #ffc922;
$lite-green-color: #0f9d58;
$normal-green-color: #008056;
$ultra-lite-green: #90f995;
$normal-blue-color: #00006e;
$black-color: #000000;
$normal-orange-color: #d36942;
$lite-orange-color: #f4a63b;
$white-color: #ffffff;
$warning-red: #ffc5c5;
$faint-purple: #c9deff;
$danger: #c80000;

$strong-orange-color: #db4437;
$border-color: #ebebeb;
$note-bg-color: #ddecfc;
$diable-color: #d9d9d9;
// Fonts Family
$outfit: "Outfit";

// Font Sizes
$d-font-30: 30px;
$d-font-24: 24px;
$d-font-22: 22px;
$d-font-20: 20px;
$d-font-18: 18px;
$d-font-16: 16px;
$d-font-14: 14px;
$d-font-12: 12px;
