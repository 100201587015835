@import "../../assets/scss/variables.scss";

.defaultBackground {
  background: $white;
  height: 100%;
}

.homeInfoSubHeading {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.homeInfoContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 500px;
}

@media (max-width: 767px) {
  .homeInfoContent {
    width: auto;
  }
}

.homeInfoMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 70px;
  color: $white;
  width: 700px;
}

.businessBackground {
  background: $black-color;
}
.bannerBackgroundMain {
  background: linear-gradient(180deg, $black-color 0%, $dark-purple 100%);
  .bannerBackGroundcolor {
    background-image: url("../../assets/images/bannerLines/lines3-blue.webp"),
    linear-gradient(90deg, #0055B1 0%, #fff 100%); ;
  }
  .bannerHeading,
  .bannerTickText {
    color: $white
  }
}

.heading1,
.heading2,
.heading3 {
  color: white;
}

.standardWidth {
  width: 80%;
}

.primaryContaienr {
  color: $white;
  height: 100vh;
}

.borderTemp {
  border: 1px solid red;
  text-align: center;
}

.sectionDefault {
  height: 80%;
  display: block;
}

.resize img {
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
}

.subHeading {
  font-size: 18px;
  font-weight: 400;
  text-shadow: 2px 2px 3px $black-color;
}

.subHeadingBold {
  font-size: 32px;
  font-weight: 600;
}

.mainHeading {
  font-size: 68px;
  font-weight: 700;
  text-shadow: 0px 0px 5px $black-color;
}

.contentHeading {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  text-shadow: 2px 2px 3px $black-color;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.highlighter {
  background-color: $dark-blue;
  color: $white;
}

.text {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}

.signUpText {
  font-size: $font-14;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: $accentColor;
}

.signUpText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

.forgotPasswordText {
  font-size: $font-12;
  font-weight: 600;
  text-align: left;
  color: $accentColor;
  cursor: pointer;
}

.forgotPasswordText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

.formWidth {
  width: 300px;
}

.eye {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.visitBtn {
  border: none;
  outline: 0 !important;
  font-size: 14px;
  cursor: pointer;
  color: $secondaryColor;
  background-color: $white;

  &:hover {
    color: $accentColor;
  }
}

@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
  .fullBleedContainer {
    background-size: 160vw auto !important;
  }
}

.backButton {
  border-radius: 100%;
  background: linear-gradient(180deg, #24a5c6 0%, #2d89ec 100%);
  color: white;
  height: 45px;
  width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.dotsMain {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
}
.dotWrapper {
  .dots {
    cursor: pointer;
    height: 5px;
    width: 5px;
    border-radius: 5px;
    background-color: $white;
    opacity: 0.4;
  }
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 5px;
}

.activeDot {
  .dots {
    cursor: pointer;
    height: 5px;
    width: 5px;
    border-radius: 5px;
    background-color: $white;
  }
  padding: 5px;
  margin: 5px;
  border: 1px solid $white;
  border-radius: 10px;
}

@media (max-width: 576px) {
  .standardWidth {
    width: 90%;
  }
  .fullBleedContainer {
    background:linear-gradient(180deg, #00006E 4.95%, rgba(0, 0, 110, 0) 46.25%), url("../../assets/images/BackgrounImages/landing_page_mobile.webp") no-repeat center !important;
    background-size: 100vw auto !important;
  }
  // .fullBleedContainer::after{
  //   content: "";
  //   display: block;
  //   margin-top: 150px;
  // }
  .homeInfoMainContainer {
    justify-content: start;
    padding: 100px 10px;
    width: auto;
  }
  .homeInfoSubHeading{
    font-size: 14px;
    font-weight: 400;
  }

  .homeInfoContent{
    font-size: 12px;
    font-weight: 400;
  }
  .mainHeading {
    font-size: 32px;
    font-weight: 700;
  }
}

/* ---- Full Bleed Container ----- */
.cookiePopup{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.fullBleedContainer {
  background: url("../../assets/images/BackgrounImages/landing_page_bg.webp") no-repeat center;
  height: 100vh;
  background-size: 100vw 100vh;
}

@media (max-width: 1400px) {
  .fullBleedContainer {
    background-size: 150vw auto;
  } 
}