.addOnsMain {
  padding: 50px 200px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%);
  color: white;
  margin: 50px 0px;
  backdrop-filter: blur(2px);
  gap: 100px;
  position: relative;
  z-index: -1;
}

.addOnsHeading {
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  margin: 10px 0px;
}

.addOnsContent {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 300px;
}

.addOnsFeature {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  padding: 20px 0px;
  width: auto;
}

.addOnsFeatureContainer {
  display: grid;
  justify-content: center;
  grid-template-columns: 400px 400px;
  gap: 10px;
}

@media (max-width: 767px) {
  .addOnsMain {
    padding: 90px;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .addOnsFeatureContainer{
    display: flex;
    flex-wrap: wrap;
  }
  .addOnsFeatureContent {
    justify-content: center;
  }
}
