.mainHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(95.13deg, #5580E9 -3.16%, #00006E 145.27%);
    padding: 60px 80px;
    margin-top: 82px;
}
.mainContainer{
    padding: 40px 60px;
}
.heading{
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: white;
}

.paragraphText{
    font-size: 16px;
    font-weight: 400;
    color: #464C67;
    line-height: 20px;
}
.paragraphHeading{
    margin-top: 50px;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    color: black;
}