.dancingBarContainer > ul{
	position: absolute;
	top:90%;
	left:-10%;
	display: flex;
	}
	.dancingBarContainer > ul li{
	list-style: none;
	width: 20px;
	height: 40px;
	background: #ffffff1e;
	margin: 0 4px;
	animation: animate 5s infinite running
	}
	@keyframes animate {
		0%{
		transform: scaleY(3);
		}
		25%{
		transform: scaleY(5);
		}
		50%{
		transform: scaleY(4);
		}
		75%{
		transform: scaleY(2);
		}
		100%{
		transform: scaleY(3);
		}
	}
	ul li:nth-child(1){
		animation-delay: .1s;
	}
		
	ul li:nth-child(2){
		animation-delay: .5s;
	}
		
	ul li:nth-child(3){
		animation-delay: 2s;
	}
		
	ul li:nth-child(4){
		animation-delay: .2s;
	}
		
	ul li:nth-child(5){
		animation-delay: 2s;
	}
		
	ul li:nth-child(6){
		animation-delay: .3s;
	}
	ul li:nth-child(7){
		animation-delay: .4s;
	}
	ul li:nth-child(8){
		animation-delay: 4s;
	}
	ul li:nth-child(9){
		animation-delay: 3s;
	}
	ul li:nth-child(10){
		animation-delay: 1s;
	}
	ul li:nth-child(11){
		animation-delay: 2s;
	}
	ul li:nth-child(12){
		animation-delay: .8s;
	}
	ul li:nth-child(13){
		animation-delay: .4s;
	}
	ul li:nth-child(14){
		animation-delay: .9s;
	}
