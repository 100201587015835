.SiteHeaderMenuMainContainer {
  display: flex;
  position: fixed;
  margin-top: 80px;
  padding: 30px 80px;
  background-color: white;
  z-index: 9999;
  width: -webkit-fill-available;
}

.SiteHeaderDarkMenuContainer {
  display: flex;
  position: fixed;
  margin-top: 80px;
  padding: 30px 80px;
  background-color: #021620;
  z-index: 9999;
  width: -webkit-fill-available;
  color: white;
}

.landing {
  display: flex;
  position: fixed;
  // margin-top: 80px;
  padding: 30px 80px;
  background-color: white;
  z-index: 9999;
  width: -webkit-fill-available;
}

.menuHoverClasses {
  border: none;
  background: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.31) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(21px);
}

.menuHoverClasses:hover {
  border: none;
  background: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.164) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  color: white;
  backdrop-filter: blur(21px);
}

.siteHeaderContact {
  width: max-content;
  margin: 0px 20px;
}

.bookDemoContainer {
  background-color: #f6f9fd;
  padding: 20px;
  border-radius: 20px;
  width: max-content;
}

.bookDemoDarkContainer {
  background: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.31) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(21px);
  padding: 20px;
  border-radius: 20px;
  width: max-content;
}

.headerMenuHeading {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}

.partialBorder {
  text-align: left;
  height: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 15px;
}
.partialBorder:after {
  content: "";
  width: 20px;
  height: 2px;
  background: black;
  position: absolute;
  border-radius: 10px;
}

.partialBorderWhite {
  text-align: left;
  height: 5px;
  position: relative;
  margin-top: 5px;
  margin-bottom: 15px;
}
.partialBorderWhite::after {
  content: "";
  width: 20px;
  height: 2px;
  background: white;
  position: absolute;
  border-radius: 10px;
}

.offeringContainer {
  //   padding: 10px;
  //   margin: 4px;
  border-radius: 20px;
  display: flex;
  text-align: left;
  width: 300px;
  svg {
    height: 40px;
  }
}

.blogsTitle {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.subMenuContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px 0px;
}

.bookDemoContent {
  text-align: left;
  margin-top: 25px;
  width: 188px;
  font-size: 12px;
  font-weight: 400;
}
