.cookieMainContainer{
    // display: flex;
    // justify-content: space-between;
    background-color: white;
    padding: 20px;
    align-items: center;
}

.cookieContainerText{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.cookieTextHeading{
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}

.cookieTextContent{
    font-size: 14px;
    font-weight: 400;
}
.link{
    color: #175CFF;
}

.buttonContainer{
    display: flex;
    justify-content: center;
    height: fit-content;
    // width: 300px;
    // min-width: 100px;
}

.button{
    margin: 12px;
    cursor: pointer;
    width: 100%;
    border: 3px solid #175CFF;
    padding: 12px;
    font-size: 14px;
    color: #175CFF;
    border-radius: 10px;
}
.button:hover{
    background-color: #F2F6FF;
}
.button1{
    margin: 12px;
    cursor: pointer;
    width: 100%;
    background-color: #175CFF;
    padding: 20px 12px;
    font-size: 14px;
    color: white;
    border: none;
    border-radius: 10px;
}
.button1:hover{
    background-color: #0E3288;
}