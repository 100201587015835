
.homeInfoSubHeading{
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.homeInfoMainHeading{
  font-size: 68px;
  font-weight: 700;
  line-height: 85px;
}


.homeInfoContent{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  width: 500px;
}

@media (max-width: 767px){
    .homeInfoContent{
      width: auto;
    }
}


