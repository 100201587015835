@import "../../assets/scss/variables.scss";
.carouselImage {
  margin: auto;
  max-width: 95%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  border-radius: 20px;
    box-shadow: 0px 5px 25px 5px rgba(1, 11, 72, 0.1);
}
.sliderCarousel {
  width: 80%;
}
.mainLines {
  display: flex;
  padding: 0px 30px;
}
.mainLinesMob {
  display: flex;
  flex-direction: column;
}
.mainLines .lines {
  background-color: $status-line-color;
  border-radius: 10px;
  height: 4px;
  width: 40%;
  margin: 0 5px;
  cursor: pointer;
}

.mainLines .lines.activeLine {
  transition: width 5s ease-in;
  position: relative;
}

.mainLines .lines.activeLine::before {
  position: absolute;
  content: "";
  width: 0;
  background-color: $accentColor;
  border-radius: 10px;
  z-index: 99;
  inset: 0;
  animation: mymove 5s ease 1;
}

.boxSelected {
  background-color: $selected-box;
  border-radius: 10px;
  margin-left: 10px;
  border: 1px solid $selected-box-border;
  .carouselBoxHeading {
    color: $accentColor;
  }
  .boxDesc {
    color: #485b74;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
  }
}

.boxNotSelected {
  border-bottom: 1px solid $hr-line-color;
}

@keyframes mymove {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.MainSlider {
  display: flex;
  margin: 0px 50px;
}

.carouselBoxHeadingContainer {
  display: flex;
}
.carouselBoxHeading {
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: $black-color;
}
.boxDesc {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  vertical-align: middle;
  color: #9a9aa3;
}

.img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.boxContent {
  // margin: 10px 0px 10px 0px;
  padding: 15px;
}

.boxContainerMain {
  box-sizing: border-box;
  width: 288px;
  background: none;
}

.mobImg {
  display: none;
}
.btnCarousel {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .sliderCarousel {
    display: none;
  }
  .mobImgSelected {
    height: auto;
    width:-webkit-fill-available;
    display: block;
  }
  .boxContainerMain {
    width: 410px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
  }
  .MainSlider {
    justify-content: center;
  }
  .hrLine {
    width: auto;
  }
}
