
.partnerImgCard{
  background: #fff;
  box-shadow: 8px 12px 48px 0px rgba(0, 0, 110, 0.12);
  padding: 20px;
  border-radius: 12px;
  width: 180px;
  display: flex;
  justify-content: center;
  margin: 50px;
  img {
    max-width: 140px;
    object-fit: contain;
  }
}
.imagesContainer {
  display: flex !important; 
  align-items: center;
  justify-content: center;
  gap: 40px;

  &:not(:first-child) {
    margin-top: 40px;
  }
}
.slider {
  height: 230px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 75%;
  padding-top: 20px;
  margin-bottom: 100px;
  z-index: -1;
}

.fader {
  position: absolute;
  width: 130px;
  height: 100%;
  top: 0;
  background: rgb(255, 255, 255);
  z-index: 999;
}

.L {
  left: -15px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}
.R {
  right: -15px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}

