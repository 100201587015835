@import "../assets/scss/variables.scss";
.mainContainer {
  overflow: hidden;
}
.formLabel {
  text-align: left;

  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;
  color: $black-color;
}

.commonInputClass {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: $font-14;
  font-weight: 400;
  border-radius: 2px;
  line-height: 1.5;
  color: $primaryColor;
  background: $inputBackgroundColor;
}

.formControl {
  border: 1px solid #e8e8e8;
}

.error {
  border: 1px solid #e8e8e8;
}

.valid {
  border: 1px solid #e8e8e8;
}

.formControl::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
}

.valid:focus {
  outline: none;
}

.error:focus {
  outline: none;
}

.formControl:disabled {
  opacity: 0.5;
  cursor: no-drop;
}

.section {
  text-align: center;
  color: $dark-purple;
  margin-bottom: 50px;
  margin-top: 100px;
}

.sectionHeadingContainer {
  margin-bottom: 10px;
}

.sectionHeading {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
  text-transform: capitalize;
}

.sectionSubHeading {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: #9a9aa3;
  margin-top: 5px;
}

.sectionContentContainer {
  padding: 20px;
}

.sectionMobileSubHeading {
  display: none;
}

@media (max-width: 650px) {
  .sectionHeading {
    font-size: 24px;
    padding: 0 70px 0 70px;
    line-height: 30px;
  }

  .sectionSubHeading {
    font-size: 14px;
    line-height: 20px;
  }
}

.faq {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
}

.menu {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  margin: auto;
  left: 0px;
  right: 0px;
  z-index: 99;
}

@media (max-width: 567px) {
  .menu {
    padding-bottom: 50px;
  }
  .faq {
    margin-top: 50px;
  }
  .section {
    margin-top: 50px;
  }
}
