.partnerContainer {
  margin-bottom: 6rem;
}

.partnerImageContainer {
  padding-left: 10px;
  margin-top: 50px;
}

.partnerImgCard {
  background: #fff;
  box-shadow: 8px 12px 48px 0px rgba(0, 0, 110, 0.12);
  padding: 20px;
  border-radius: 12px;
  width: 180px;
  display: flex;
  justify-content: center;
  margin: 32px;
  img {
    max-width: 140px;
    object-fit: contain;
  }
}
.middleCarousel{
  padding-top: 0px;
  margin: auto;
  justify-content: center;
  width: 80%;
}
.imagesContainer {
  display: flex !important; 
  align-items: center;
  justify-content: center;
  gap: 50px;
  &:not(:first-child) {
    margin-top: 0px;
  }
}

.fader {
  position: absolute;
  width: 130px;
  height: 20%;
  // top: 0;
  background: rgb(255, 255, 255);
  z-index: 999;
}

.L {
  // left: -15px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}
.R {
  right: 10%;
  margin-top: -10%;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
}


@media (max-width: 1024px) {
  .partnerContainer{
    width: 90%;
    margin: 0 auto;
  }
}