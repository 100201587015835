.main {
  background: linear-gradient(95.13deg, #5580e9 -3.16%, #00006e 145.27%);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 10px;
  padding-top: 160px;
}
.bannerBackground {
  margin: 0% 10%;
  background: url("../../assets/images/bannerLines/white_lines.webp"),
    linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%) cover center
      no-repeat;
}
.heading {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
}

.recentBlogs {
  display: flex;
  padding: 50px;
  gap: 20px;
  justify-content: center;
}
.otherRecentBlogs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}
.otherDetailRecentBlogs {
  overflow: hidden;
  width: 90%;
  display: flex;
  flex-direction: row;
  border: 1px solid #ababab4d;
  border-radius: 20px;
}

.firstRecentBlog {
  width: min-content;
  border: 1px solid #ababab4d;
  border-radius: 20px !important;
}
.headingBlogs {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
  text-align: center;
  color: black;
  margin-bottom: 20px;
}
.paginationButtonNumber {
  margin: 10px;
  padding: 2px 10px;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 5px;
  width: min-content;
}
.paginationButton {
  margin: 10px;
  padding: 2px 10px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  width: min-content;
}
.activePage {
  background-color: #5580e9;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.subHeading {
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #b7b7b7;
}

.blogs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.blogsCategory {
  margin: 10px 20px;
  // display: flex;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  height: min-content;
  white-space: nowrap;
  padding: 30px 50px;
  border-radius: 16px;
  width: 360px;
  margin-bottom: 20px;
  overflow-x: auto;

  > div {
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    width: fit-content;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  &__categoryName {
    text-transform: capitalize;
    font-size: 20px;
    margin: 3px;
    height: min-content;
    cursor: pointer;
    font-weight: 400;
    opacity: 0.7;
    &__selected {
      opacity: 1;
      font-weight: 500;
      color: #0075f3;
    }
  }
}
.headingCategory {
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px solid #e6e6e6;
  gap: 10px;
  padding-bottom: 20px;
}
.blogCard {
  width: min-content;
  border: none !important;
  &__card-body {
    margin-top: 15px;
  }
  &__card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  &__card-date {
    font-size: 14px;
    color: #999999;
  }
}
.blogCard:hover {
  cursor: pointer;
  scale: 1.01;
  transition: scale 0.2s ease-in-out;
}

.grid {
  display: grid;
  grid-template-columns: auto auto;
  margin: 15px 20px;
  gap: 40px;

  // align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.emptyBlog {
  display: flex;
  margin: 15px 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  span {
    font-size: 20px;
    opacity: 0.7;
  }
}

@media (max-width: 1100px) {
  .grid {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 600px) {
  .grid {
    grid-template-columns: auto;
    margin-top: 20px;
  }
}
