.esignBannerContainer {
  display: flex;
  justify-content: space-between;
  padding: 0px 70px;
  //   margin: 0px 30px;
  background: url("../../assets/images/bannerLines/bg_pattern.png"), #6b52ad;
  background-repeat: no-repeat;
  background-position: center;
  //   position: relative;
}
.esignTextContainer {
  width: 35%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.esignText {
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  color: white;
}

.esignImage {
  height: 200px;
  width: 495px;
}
