@import "../../assets/scss/variables.scss";

.flatCard {
  border: 1px solid #e2e5e8;
  border-radius: 30px;
  padding: 44px 24px;
  width: 300px;
  margin: 15px;
  text-align: left;
}

.flatCardHeading {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: $dark-purple;
  margin-top: 20px;
  margin-bottom: 10px;
}

.flatCardSubHeading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $dark-grey;
}

@media (max-width: 600px) {
  .flatCardHeading {
    text-align: center;
    width: 100%;
  }
  .flatCard {
    text-align: center;
    justify-content: center;
  }
  .flatCardSubHeading {
    display: flex;
    justify-content: center;
  }
}
