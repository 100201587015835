.businessCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.bannerBackground {
  background: url("../../assets/images/bannerLines/white_lines.webp"),
    linear-gradient(90deg, #21329e -0.28%, #4d74dd 100.52%);
  background-size: cover;
}

.svgMenu {
  margin: auto;
}

.mainImage {
  width: 70%;
  height: 340px;
  margin: 10px;
}
.image1 {
  margin: 10px;
  width: 24%;
  height: auto;
}
.image2 {
  margin: 10px;
  width: 30.33%;
  height: 340px;
}

.productIdentityBackground {
  background: url("../../assets/images/BackgrounImages/identity_bg.webp")
    no-repeat center;
  height: 100vh;
  background-size: 100vw 100vh;
}
.navigation {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: black;
  padding: 5px;
  border: 1px solid #868ebb;
  border-radius: 50px;
  background-color: white;
  // background-color: rgba(255, 255, 255, 0.4);
  // backdrop-filter: blur(4px);

  &__active-state {
    position: absolute;
    color: white;
    background: linear-gradient(90.14deg, #00006e -72.99%, #5580e9 118.57%);
    padding: 0px 20px;
    height: calc(100% - 8px);
    border-radius: 50px;
    top: 4px;
    left: 4px;
    opacity: 0;
    transition: left 0.4s ease-out, width 0.1s ease-out;
  }

  &__btn {
    display: flex;
    cursor: pointer;
    color: #363648;
    padding: 0px 25px;
    transition: color 0.5s ease-out;
    border: none;
    background: none;
    z-index: 1;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      color: white;
      pointer-events: none;
      background: linear-gradient(90.14deg, #00006e -72.99%, #5580e9 118.57%);
      padding: 0px 20px;
      height: calc(100% - 8px);
      border-radius: 50px;
      transition: background 0.4s ease-in;
    }
  }
}

@media (max-width: 767px) {
  .productIdentityBackground {
    background: none;
    min-height: 100vh;
    height: fit-content;
  }
  // .productVerifySection{
  //   padding-top: 350px;
  // }
  .navigation {
    border: none;
    justify-content: center;
    &__active-state {
      background: transparent;
    }

    &__btn {
      width: 45%;
      justify-content: center;
      // flex: 1;
    }
  }
  .selected {
    background: linear-gradient(90deg, #00006e 0.36%, #5580e9 99.05%);
    border-radius: 50px;
  }
}

@media (max-width: 567px) {
  .navigation {
    &__btn {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
