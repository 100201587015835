.menuHover {
  // display: flex;
  border: 1px solid #e1eeff;
  border-radius: 10px;
  padding: 30px 30px;
  width: 280px;
}

.darkMenuHover {
  border: none !important;
  border-radius: 10px;
  padding: 30px 30px;
  width: 280px;
  background: radial-gradient(
    231% 135.8% at 0.9% 2.98%,
    rgba(2, 22, 32, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.menuLink {
  text-decoration: none;
  color: inherit;
}

.menuLink:hover {
  text-decoration: none;
  color: inherit;
}

.menuHover:hover {
  background-color: #f6f9fd;
  border: 1px solid #00006e;
  color: #00006e;
}
.productsImg {
  height: 50px;
  margin: 2px 10px;
}

.submenuHeading {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.subMenuHeadingContent {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
