@import "../../assets/scss/variables.scss";
.mainBanner {
  background-image: url("../../assets/images//bannerLines/white_lines.webp"),
    linear-gradient(90deg, #5580e9 16.22%, #00006e 100%);
  height: auto;
  width: 100%;
  margin: auto;
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 22px;
  padding: 60px;
}

.bannerContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 120px;
}

.headBanner {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: $white;
  width: 65%;
}
/* bannerCard.module.scss */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensure the modal is on top of other elements */
}

.modalContent {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 230px;
  border: none;
  background-color: transparent;
  color: #293DA8;
  font-size: 16px;
  cursor: pointer;
  z-index: 9999;
}

.closeButton:hover {
  color: black;
}

/* Rest of your CSS styles */

.buttonContainer {
  margin: 40px 0;
}

.svgBanner {
  margin-left: 15px;
  margin-right: 5px;
}

.Btext {
  color: $white;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
}

.imageMain {
  text-align: right;
}

@media (max-width: 800px) {
  .svgBanner {
    margin: 7px;
    vertical-align: middle;
  }
  .imgbanner {
    display: none;
  }
  .headBanner {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }
  .mainBanner {
    width: auto;
    margin: 16px;
    padding: 50px 30px;
    text-align: center;
    // background-image: linear-gradient(90deg, #a9bff5 16.22%, rgba(6, 73, 241, 0) 100%);
  }
  .Btext {
    text-align: left;
  }
  .Btext > div {
    width: 50%;
    vertical-align: middle;
  }
}

@media (max-width: 567px) { 
  .bannerContainer {
    padding-bottom: 50px;
  }
}
