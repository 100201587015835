@import "../../assets/scss/variables.scss";

.mainDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #010812;
}

.flatCard {
  border: none !important;
}

.imgText {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: $white;
  position: absolute;
}
.imgSubText {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: $white;
}

.businessCardheading {
  color: $white !important;
}
.businessCardsubHeading {
  color: $dark-grey !important;
}

.imgDetail img {
  height: auto;
  width: 650px;
  display: block;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.businessMainHeading {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: white;
  padding: 20px 40px;
  text-align: center;
}
.businessCardsContainer {
  flex-wrap: nowrap;
}
.businessCardsContainer,
.businessCardsParentContainer {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin: auto;
}

.businessCardsParentContainer {
  flex-wrap: wrap;
  padding-left: 100px;
  padding-top: 50px;
}

@media (max-width: 1180px) {
  .Detail {
    margin: 0;
  }
  .imgDetail {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .detailHead {
    font-size: 22px;
    font-weight: 500;
    margin: 0px 100px;
    line-height: 27px;
  }
  .mainDetails {
    flex-wrap: wrap;
  }
  .businessCardsParentContainer {
    padding-left: 0;
    padding-top: 50px;
  }
  .businessCardsContainer {
    flex-wrap: wrap;
    width: auto;
    justify-content: center;
  }
  .businessHeading {
    position: relative;
    margin: 0;
  }
  .businessMainHeading {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
